<template>
    <div :style="'height:' + innerHeight + 'px;vertical-align: bottom;'">
        <div class="bg"></div>
        <div class="e">
            <Card style="width: 400px; border: 1px solid #eeeeee" shadow bordered >
                <p slot="title" class="title-style">兼职管理系统</p>
                <div style="margin: 10px 30px;">
                    <Form ref="formInline" :model="formInline" :rules="ruleInline">
                        <FormItem prop="account">
                            <Input type="text" v-model="formInline.account" placeholder="请输入账户">
                                <Icon size="18" type="ios-person-outline" slot="prepend"></Icon>
                            </Input>
                        </FormItem>
                        <FormItem prop="pwd">
                            <Input type="password" v-model="formInline.pwd" placeholder="请输入密码">
                                <Icon size="18" type="ios-lock-outline" slot="prepend"></Icon>
                            </Input>
                        </FormItem>
                        <FormItem>
                            <div style=" display: flex;flex-direction: row;align-items: center;cursor: pointer;" @click="goTick">
                                <div class="card-btn">
                                    <img v-if="tickValue" class="btn-img" src="@/assets/login/tick.png" alt="">
                                </div>
                                <div class="card-p">记住用户名和密码</div>
                            </div>
                        </FormItem>
                        <FormItem>
                            <Button type="primary" style="background: #44ABF0;width: 100%;" @click="handleSubmit('formInline')">登录</Button>
                        </FormItem>
                    </Form>
                </div>
            </Card>
        </div>
    </div>
</template>
<script>
import { login } from "@/api/index";
import { setCookies } from "@/utils/helper";
export default {
    data() {
        return {
            innerHeight: 0,
            formInline: {
                account: '',
                pwd: ''
            },
            ruleInline: {
                account: [
                    { required: true, message: "账户不能为空", trigger: "blur" }
                ],
                pwd: [
                    { required: true, message: "密码不能为空", trigger: "blur" }
                ]
            },
            tickValue: true
        };
    },
    mounted() {
        this.restHeight();
    },
    created() {
        var _this = this;
        document.onkeydown = function () {
            if (_this.$route.name === "登录") {
                let key = window.event.keyCode;
                if (key === 13) {
                    _this.handleSubmit("formInline");
                }
            }
        };
    },
    methods: {
        goTick(){
            this.tickValue = !this.tickValue
        },
        /**修改高度 */
        restHeight() {
            this.innerHeight = window.innerHeight - 2;
        },
        handleSubmit(name) {
            let menu = [
                {
                    children: [],
                    icon: "md-home",
                    id: 1,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin",
                    title: "首页",
                    unique_auth: "home",
                },
                {
                    children: [],
                    icon: "md-person",
                    id: 2,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/jobmanage",
                    title: "职位管理",
                    unique_auth: "jobmanage",
                },
                {
                    children: [],
                    icon: "md-list-box",
                    id: 3,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/application",
                    title: "报名记录",
                    unique_auth: "application",
                },
                {
                    children: [],
                    icon: "ios-chatbubbles",
                    id: 4,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/messageManage",
                    title: "消息管理",
                    unique_auth: "messageManage",
                },
                {
                    children: [],
                    icon: "logo-usd",
                    id: 5,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/salary",
                    title: "工资结算",
                    unique_auth: "salary",
                },
                {
                    children: [],
                    icon: "ios-people",
                    id: 6,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/aBonus",
                    title: "分红记录",
                    unique_auth: "aBonus",
                },
                {
                    children: [],
                    icon: "md-card",
                    id: 7,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/withdrawal",
                    title: "提现",
                    unique_auth: "withdrawal",
                },
                {
                    children: [
                        {
                            id: 9,
                            is_menu: 2,
                            pid: 0,
                            src: "/systemsetup/changepassword",
                            title: "修改密码",
                            unique_auth: "changepassword",
                        },
                        {
                            id: 10,
                            is_menu: 2,
                            pid: 0,
                            src: "/systemsetup/business",
                            title: "商家信息",
                            unique_auth: "business",
                        },
                    ],
                    icon: "md-settings",
                    id: 8,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/systemsetup",
                    title: "系统设置",
                    unique_auth: "systemsetup",
                },
            ];
            this.$refs[name].validate((valid) => {
                if (valid) {
                    login(this.formInline)
                        .then((res) => {
                            setCookies("authori-zation-jobs-pc", res.data.token);
                            localStorage.setItem(
                                "userName",
                                JSON.stringify(res.data.account)
                            );
                            this.$store.commit("setMenus", menu);
                            this.$Message.success(res.msg);
                            this.$router.push({ path: "/admin" });
                        })
                        .catch((res) => {
                            this.$Message.error(res.msg);
                        });
                } else {
                    this.$Message.error("数据不能为空");
                }
            });
        },
    },
};
</script>
<style scoped>
.ivu-icon {
    width: 30px;
}
.e {
    margin-top: -20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 999;
}
.bg {
    background-image: url("../../assets/login/bg.png");
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}
.title-style{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 30px;
    color: #44ABF0;
    text-align: center;
    height: 40px;
    line-height: 40px;
}
.card-btn{
    width: 18px;
    height: 18px;
    background: #44ABF0;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-img{
    width: 10px;
    height: 10px;
}
.card-p{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #676767;
}
</style>
